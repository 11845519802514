import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'EnterData',
    meta: {
      title: '车抵贷',
    },
    component: () => import(/* webpackChunkName: "router" */ '../views/enter_data/Index.vue'),
  },
  {
    path: '/second',
    name: 'Second',
    meta: {
      title: '车抵贷',
    },
    component: () => import(/* webpackChunkName: "router" */ '../views/enter_data/Second.vue'),
  },
  {
    path: '/third',
    name: 'Third',
    meta: {
      title: '车抵贷',
    },
    component: () => import(/* webpackChunkName: "router" */ '../views/enter_data/Third.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  to.meta && to.meta.title && (document.title = to.meta.title);
  if (to.meta && to.meta.anonymous) {
    return next()
  }
  next()
});

export default router;
