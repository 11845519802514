const moment = window.moment;
const geolocation = new window.BMapGL.Geolocation();

moment.locale('zh-cn');

// 获取 定位
export const getLocation = async () => {
  return new Promise((resolve, reject) => {
    // 开启SDK辅助定位
    geolocation.enableSDKLocation();
    geolocation.getCurrentPosition((r) => {
      if (geolocation.getStatus() == window.BMAP_STATUS_SUCCESS) {
        // console.log(`百度坐标位置：${r.point.lng},${r.point.lat}`);

        resolve({
          longitude: r.point.lng,
          latitude: r.point.lat,
        });

        /**
         * 国测
         */
        /* var x_pi = (3.14159265358979324 * 3000.0) / 180.0;
          const baiduTomars = (baidu_point) => {
            var mars_point = { lng: 0, lat: 0 };
            var x = baidu_point.lng - 0.0065;
            var y = baidu_point.lat - 0.006;
            var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
            var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
            mars_point.lng = z * Math.cos(theta);
            mars_point.lat = z * Math.sin(theta);
            return mars_point;
          };
          const o = baiduTomars({ lng: r.point.lng, lat: r.point.lat });
          console.log(`国测坐标位置：${o.lng},${o.lat}`); */

        //
      } else {
        // alert('获取定位失败：' + geolocation.getStatus());
        reject('获取定位失败');
      }
    });

    //
  });

  //
}

// 是否车牌（含新能源车牌）
export const isVehicleNumber = (val = '') => {
  var xreg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z](?:((\d{5}[A-HJK])|([A-HJK][A-HJ-NP-Z0-9][0-9]{4}))|[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳])$/;
  var creg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/;
  if (val.length == 7) {
    return creg.test(val);
  } else if (val.length == 8) {
    return xreg.test(val);
  } else {
    return false;
  }
};

// 是否为身份证号
export const isCardId = val => {
  const re = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;
  return re.test(val);
};

// 是否手机号
export const isMobile = val => {
  return /^1[3456789][0-9]{9}$/.test(val);
};

/**
 * 日期格式化
 * @description 基于 moment 的日期格式化
 * @param {Number|String|Date} value
 * @param {String} format 更多配置参考 moment 文档 <https://momentjs.com/docs/>
 * @param {String} unlimitedText
 * @returns String
 */
export const dateFormat = (value, format = 'YYYY-MM-DD HH:mm:ss', unlimitedText = '') => {
  if (!value) return '';
  value = 'string' === typeof value ? value.replace(/-/g, '/') : value;
  const date = new Date(value);
  if (Number.isNaN(date.getTime())) return '';
  if (9999 === date.getFullYear()) return unlimitedText;
  return moment(date).format(format);
};

/**
 * 千分位数字格式化
 * @param {Number|String} value
 * @param {int} fixed 修正位数
 * @returns {null|String}
 */
export const amount = (value, fixed = 2) => {
  if (isNaN(value)) return null;
  if ([null, undefined, ''].includes(value)) return null;
  if (0 === value) return parseFloat(0).toFixed(fixed);
  let num = value.toString().replace(/,/g, '');
  num = parseFloat(num || 0);
  num = num.toFixed(+fixed || 0).toString();
  num = num.split('.');
  if (1 === num.length) return num[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  return [num[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'), num[1]].join('.');
};

/**
 * 单元格金额处理
 * @param {Number|String} value
 * @param {int} placeholder 值为空时的占位符
 * @param {int} fixed 修正位数
 * @returns String
 */
export const columnAmount = (
  value,
  { placeholder = '-', fixed = 2, prefix = '', suffix = '' } = {}
) => {
  if (0 === value) return 0;
  if (isNaN(value)) return placeholder;
  const _amount = amount(value, fixed);
  return null === _amount ? placeholder : prefix + _amount + suffix;
};

// 去除左右空格
export const trimValue = (value) => {
  const _trim = (val) => val.trimLeft().trimRight();

  // 字符串直接过滤
  if ('string' === typeof value) return _trim(value);

  // 对象递归过滤
  if ('[object Object]' === Object.prototype.toString.call(value)) {
    for (const key in value) {
      const _value = value[key];
      if ('string' === typeof _value) {
        value[key] = trimValue(_value);
      }
    }
    return value;
  }

  // 数组
  if (Array.isArray(value)) {
    value = [...value].map((set) => trimValue(set));
  }

  // 其他不予处理
  return value;
};

// 去除空值参数，包含空字符串、空数组、空对象
// value 仅支持 对象 和 数组 类型
export const removeEmpty = (value) => {
  const _empty = (data) => {
    if ('' === data) return true;
    if (Array.isArray(data) && !data.length) return true;
    if ('{}' === JSON.stringify(data)) return true;
    return false;
  };

  if (value instanceof Object) {
    for (const key in value) {
      if (_empty(value[key])) {
        delete value[key];
      }
    }
    return value;
  } else if (Array.isArray(value)) {
    // 数组处理
    return [...value].filter((set) => !_empty(set));
  }
  return value;
};
