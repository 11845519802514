import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import vant from 'vant';
Vue.use(vant);

// 自定义过滤器
import filter from '@/filter';
Vue.use(filter);


Vue.config.productionTip = false;

Vue.prototype.window = window;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
